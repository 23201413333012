import React from "react";
import pokeballImg from "../img/pokeball.gif";
import runImg from "../img/run.gif";
import "./pokeball.css";
function Pokeball({ action, scapeAction }) {
  return (
    <>
      <div className="main-container">
        <div class="tooltip">
          <img
            src={pokeballImg}
            alt="pokemon"
            width="200px"
            height="200px"
            onClick={action}
          ></img>
          <span class="tooltiptext">Try Pokeball</span>
        </div>
        <div class="tooltip">
          <img
            src={runImg}
            width="200"
            height="250px"
            alt="pokemon"
            onClick={scapeAction}
          ></img>
          <span class="tooltiptext">run away</span>
        </div>
      </div>
    </>
  );
}

export default Pokeball;
