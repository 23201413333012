import React from "react";

function Pokemon({ name, image }) {
  return (
    <>
      <div className="main.container">
        <h1>{name}</h1>
        <div className="image-container">
          <img src={image} width="300" height="300px" alt="pokemon"></img>
        </div>
      </div>
    </>
  );
}

export default Pokemon;
