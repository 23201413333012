/* eslint-disable no-undef */
import "./App.css";
import axios from "axios";
import Pokemon from "./components/Pokemon";
import { useState, useEffect } from "react";
import Pokeball from "./components/Pokeball";
import Footer from "./components/Footer";
import Confetti from "react-confetti";

function App() {
  const [captured, setCaptured] = useState(
    parseInt(Math.random() * (10 - 1) + 1) % 2 === 0
  );

  const [showConfetti, setShowConfetti] = useState(false);

  const [myPokemons, setMyPokemons] = useState([]);
  const [pokemon, setPokemon] = useState({});

  const max = 850;
  const min = 0;
  function getPokemon() {
    let pokeNumber = parseInt(Math.random() * (max - min) + min);
    axios
      .get(`https://pokeapi.co/api/v2/pokemon/${pokeNumber}`)
      .then((response) => {
        debugger;
        setPokemon({
          image: response.data.sprites.other["official-artwork"].front_default,
          icon: response.data.sprites.front_default,
          name: response.data.name,
        });
      });
  }
  useEffect(() => {
    getPokemon();
  }, [myPokemons]);

  function handleClickPokeball() {
    if (captured) {
      setShowConfetti(true);
      alert("Woooowwww this poke is your now");

      let pokemons = myPokemons;
      pokemons.push(pokemon);
      setMyPokemons(pokemons);

      setTimeout(() => {
        getPokemon();
        setShowConfetti(false);
      }, 5500);
    } else {
      alert("ooh nooo, the pokemon fled");
      getPokemon();
    }
    setCaptured(parseInt(Math.random() * (10 - 1) + 1) % 2 === 0);
  }

  return (
    <div className="App">
      <header className="App-header">
        {showConfetti && <Confetti width={2000} height={1800} />}
        <Pokemon name={pokemon.name} image={pokemon.image} />
        <Pokeball action={handleClickPokeball} scapeAction={getPokemon} />
        <Footer pokemons={myPokemons} />
      </header>
    </div>
  );
}

export default App;
