import React from "react";
import "./footer.css";
function Footer({ pokemons }) {
  return (
    <div className="footer">
      <div>
        {pokemons.length > 0 && (
          <>
            <h4>captured pokemons</h4>
            <div className="captured-area">
              {pokemons.map((p) => (
                <img alt="poke-icon" src={p.icon}></img>
              ))}
            </div>
          </>
        )}
      </div>

      <footer>
      </footer>
    </div>
  );
}

export default Footer;
